import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=042577c6&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"
import style0 from "./Header.vue?vue&type=style&index=0&id=042577c6&scoped=true&lang=scss&"
import style1 from "./Header.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "042577c6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BadgeIcon: require('/var/www/dockerize-nuxt/agora-app/components/BadgeIcon.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VTab,VTabs,VTabsSlider})
