import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_cc2138b4 from 'nuxt_plugin_plugin_cc2138b4' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_1f2cf208 from 'nuxt_plugin_plugin_1f2cf208' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_image_f2f75692 from 'nuxt_plugin_image_f2f75692' // Source: ./image.js (mode: 'all')
import nuxt_plugin_vuesweetalert2_7900035a from 'nuxt_plugin_vuesweetalert2_7900035a' // Source: ./vue-sweetalert2.js (mode: 'client')
import nuxt_plugin_nuxtvuexroutersync_5727a8a4 from 'nuxt_plugin_nuxtvuexroutersync_5727a8a4' // Source: ./nuxt-vuex-router-sync.js (mode: 'all')
import nuxt_plugin_pluginclient_d7aa27bc from 'nuxt_plugin_pluginclient_d7aa27bc' // Source: ./content/plugin.client.js (mode: 'client')
import nuxt_plugin_pluginserver_0c3744ac from 'nuxt_plugin_pluginserver_0c3744ac' // Source: ./content/plugin.server.js (mode: 'server')
import nuxt_plugin_axios_c9055088 from 'nuxt_plugin_axios_c9055088' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_moment_3a4d9d48 from 'nuxt_plugin_moment_3a4d9d48' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_veevalidate_1a0c172c from 'nuxt_plugin_veevalidate_1a0c172c' // Source: ../plugins/vee-validate.ts (mode: 'all')
import nuxt_plugin_vueloadingoverlay_f6a106aa from 'nuxt_plugin_vueloadingoverlay_f6a106aa' // Source: ../plugins/vue-loading-overlay.ts (mode: 'all')
import nuxt_plugin_vueimagelazyload_3bcc08fd from 'nuxt_plugin_vueimagelazyload_3bcc08fd' // Source: ../plugins/vue-image-lazy-load.ts (mode: 'all')
import nuxt_plugin_clickaway_07e41e84 from 'nuxt_plugin_clickaway_07e41e84' // Source: ../plugins/click-away.ts (mode: 'all')
import nuxt_plugin_axiosaccessor_557363f9 from 'nuxt_plugin_axiosaccessor_557363f9' // Source: ../plugins/axios-accessor.ts (mode: 'all')
import nuxt_plugin_mixpanel_17e60bcf from 'nuxt_plugin_mixpanel_17e60bcf' // Source: ../plugins/mixpanel.ts (mode: 'client')
import nuxt_plugin_auth_08be69f4 from 'nuxt_plugin_auth_08be69f4' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_auth_7f755f62 from 'nuxt_plugin_auth_7f755f62' // Source: ../plugins/auth.ts (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Agora","htmlAttrs":{"lang":"en"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"name":"robots","content":"noindex"},{"name":"googlebot","content":"noindex"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002FFavicon.png"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Roboto:100,300,400,500,700,900&display=swap"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002F@mdi\u002Ffont@latest\u002Fcss\u002Fmaterialdesignicons.min.css"}],"__dangerouslyDisableSanitizers":["script"],"script":[{"hid":"segment-script","innerHTML":"\n        !function() {\n          var analytics = window.analytics = window.analytics || []\n          if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error(\"Segment snippet included twice.\"); else {\n            analytics.invoked = !0; analytics.methods = [\"trackSubmit\", \"trackClick\", \"trackLink\", \"trackForm\", \"pageview\", \"identify\", \"reset\", \"group\", \"track\", \"ready\", \"alias\", \"debug\", \"page\", \"once\", \"off\", \"on\"]; analytics.factory = function (t) { return function () { var e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics } }; for (var t = 0; t \u003C analytics.methods.length; t++) { var e = analytics.methods[t]; analytics[e] = analytics.factory(e) } analytics.load = function (t, e) {}; analytics.SNIPPET_VERSION = \"4.1.0\"\n            analytics.page() \u002F\u002F Uncomment if your application is NOT an SPA\n          }\n        }()","type":"text\u002Fjavascript","charset":"utf-8","defer":true},{"charset":"utf-8","type":"text\u002Fjavascript","src":"\u002F\u002Fjs.hsforms.net\u002Fforms\u002Fv2.js?pre=1","defer":true}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_cc2138b4 === 'function') {
    await nuxt_plugin_plugin_cc2138b4(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_1f2cf208 === 'function') {
    await nuxt_plugin_plugin_1f2cf208(app.context, inject)
  }

  if (typeof nuxt_plugin_image_f2f75692 === 'function') {
    await nuxt_plugin_image_f2f75692(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuesweetalert2_7900035a === 'function') {
    await nuxt_plugin_vuesweetalert2_7900035a(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtvuexroutersync_5727a8a4 === 'function') {
    await nuxt_plugin_nuxtvuexroutersync_5727a8a4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_d7aa27bc === 'function') {
    await nuxt_plugin_pluginclient_d7aa27bc(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_0c3744ac === 'function') {
    await nuxt_plugin_pluginserver_0c3744ac(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_c9055088 === 'function') {
    await nuxt_plugin_axios_c9055088(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_3a4d9d48 === 'function') {
    await nuxt_plugin_moment_3a4d9d48(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_1a0c172c === 'function') {
    await nuxt_plugin_veevalidate_1a0c172c(app.context, inject)
  }

  if (typeof nuxt_plugin_vueloadingoverlay_f6a106aa === 'function') {
    await nuxt_plugin_vueloadingoverlay_f6a106aa(app.context, inject)
  }

  if (typeof nuxt_plugin_vueimagelazyload_3bcc08fd === 'function') {
    await nuxt_plugin_vueimagelazyload_3bcc08fd(app.context, inject)
  }

  if (typeof nuxt_plugin_clickaway_07e41e84 === 'function') {
    await nuxt_plugin_clickaway_07e41e84(app.context, inject)
  }

  if (typeof nuxt_plugin_axiosaccessor_557363f9 === 'function') {
    await nuxt_plugin_axiosaccessor_557363f9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_mixpanel_17e60bcf === 'function') {
    await nuxt_plugin_mixpanel_17e60bcf(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_08be69f4 === 'function') {
    await nuxt_plugin_auth_08be69f4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_auth_7f755f62 === 'function') {
    await nuxt_plugin_auth_7f755f62(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
